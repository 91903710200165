/**********
 * Theme file: style-goldblue.scss
 *
 * EBK CSS - GOLD BLUE theme
 */

 // Main theme colors
$main-theme: #f17f0c;
$main-theme-hover: lighten($main-theme, 40%);
$main-theme-complementary: #003374;

// Theme specific variable settings
$calendar-highlight-color: lighten($main-theme, 20%);

// policy banner buttons should be green
$policy-banner-accept-button-color:     #64D241;
$policy-banner-accept-hover-color:      #70EF4A;

// Import color variables
@import '../scss-ebk/variables-ebk';

$map-colors: ($main-theme, $main-theme-complementary, $green, $white, $red) !default;

// Import EBK specific files
@import '../scss-ebk/imports-ebk';


// Additional styles for this theme
.ap-ebkcalendar .fc-h-event {
	background-color: $main-theme;
    border: $main-theme;
}

.ap-ebkcalendar .calmaybeholiday {
	background-color:lighten($main-theme, 40%);
    border: lighten($main-theme, 40%);
}